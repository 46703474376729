@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

/* Top Navbar Styling */
.navbar-brand{
  margin-left: 2rem;
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  color: #3A3A3A !important;
  font-weight: 700;
  margin-top: 0;
  cursor: pointer;

}

.navbar{
  margin-bottom:0rem !important;
}

.top-nav .navbar-nav{
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  margin-top:-0.12rem;
  padding-top: 0rem;
  padding-bottom: 1rem;
}

.top-nav .nav-item{
  margin-right: 5rem;
  margin-bottom: -2.2rem;
  cursor: pointer;

}

.top-nav .nav-item:last-child{
  margin-right: 0;
}

.top-nav.nav-link{
    margin-bottom:0.1rem !important;
}

.top-nav .nav-item .active{
  border-bottom: 2.8px solid;
}

.top-nav .navbar {
  padding: 0.5rem 1rem 0rem 1rem;
  border-bottom: 3px solid #F0F0F0;
  padding-bottom: 0rem;
  height: 10vh;
}

.navbar-expand{
  border-bottom: 3px solid #E5E5E5;
  padding-bottom: 0px;
}

/*Left Navbar Styling */

.sidebar {
  min-width:320px;
  height: 93.5vh;
  padding-top: 4.8rem;
  padding-left: 0;
  padding-right: 0;
  flex-direction: column;
}

.left-nav .navbar-expand{
  border-bottom: none;
  padding-right: 0;
  padding-left: 0;
}

.left-nav .nav-item{
  width:100%;
  margin:0;
  padding:0;
}

.tactorinfonav{
  width:80%;
  padding-left:0;
  margin-left: 3.8rem;
  margin-bottom: .8rem;
  border-radius: 1rem;
  border: 1px solid #CACACA;
}

.tactorinfonav.active{
  border: 1px solid #3A3A3A;
}

.tactorinfoicon{
  width:100%;
  height:100%;
}

.tactoricon.active{
  background-color: #3A3A3A;
  border-radius: 1rem;
}

.tactoricon{
  width: 4rem;
  height: 4rem;
  padding: 5px;
  display: inline-block !important;
  margin-left: 1.3rem;
  margin-right:0;
}

.col.tactinfo{
  padding-left:0;
  padding-top:.5rem;

}

.tactinfo{
  color: #3A3A3A;
  font-weight: 400;
  display:inline;
}

.infotab{
  min-width: 6.5rem !important;
}

.infotab > .title {
  /* display: block !important; */
}

.infotab > .subtitle {
}

img.tactinfo{
  width:10px;
  height:10px;
  margin-left:.2rem;
  margin-right:.2rem;
}


a.tactorlink{
  display: inherit !important;
  margin:0;
  padding:0;
}

.left-nav .nav-item .active{
  background-color: #3A3A3A;
}


.left-nav.nav-link{
  color: #9C9C9C;
  cursor: pointer;
}

.left-nav .nav-link .active{
  color: #FFFFFF;
}

.left-nav .icon{
  margin: -.1rem 1.2rem 0rem 20%;
}

.sidebar-emotion{
  width: 4.5rem;
  margin-bottom: 0rem;
}

.sidebar-emotion-box h4{
  margin:0rem;
}

.sidebar-emotion-box{
  padding:.6rem 4.2rem;
  margin-left: 3.8rem;
  margin-bottom: .8rem;
  border-radius: 1rem;
  border: 1px solid #CACACA;
}

.sidebar-tactor{
  width: 4.5rem;
  margin-bottom: 0rem;
}

.sidebar-tactor-box h4{
  margin:0rem;
}

.sidebar-tactor-box{
  padding:.6rem 4.2rem;
  margin-left: 3.8rem;
  margin-bottom: .8rem;
  margin-top: .3rem;
  border-radius: 1rem;
  border: 1px solid #CACACA;
}

.emotion h4{
  margin: 0rem !important;
}

h4.home-connect {
  margin: 0rem !important;
  max-width: 100rem !important;
}

.graph{
  margin: 1rem 1rem 2rem 1rem;
}



/*Main Content Styling*/

.group1{
  ms-flex-wrap: nowrap!important;
  flex-wrap: nowrap!important;
  max-width: 87vw;
}

.page {
  display: table;
  width: 100vw;
}

.page-content,
.sidebar {
  display: table-cell !important;
}

.page-content{
  width:100vw;
}

.row .emotion{
  min-height:8rem;
}

section .emotion-table{
  margin-left: 1rem;
}

.emotion-box{
  border-radius: 1rem;
  border: 1px solid #CACACA;
  max-width: 9rem;
  max-height:9rem;
  padding:0rem 1.6rem;
  margin-bottom: .8rem;
  margin-top: 2rem;
  cursor: pointer;
}

.row .home-connect-row{
  height: 100% !important;
  width: 100% !important;
}

.home-connect-row > .col {
  width: auto;
  padding: 1.8rem .1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: .5rem;
  margin-right: .5rem;
}

.row .tactor1-connect-row{
  height: 100% !important;
  width: 100% !important;
  margin-top: .75rem;
}

.tactor1-connect-row > .col {
  width: auto;
  padding: 1.8rem .1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: .5rem;
  margin-right: .5rem;
}

.tactor1-connect-row-subtitle {
  font-size: 1.2rem;
}

.sub-row{
  margin-top: 1rem;
  border: 1px solid #3A3A3A;
  border-radius: 20px;
}

figure > img {
  width: auto;
}

.fig-border{
  border: 1px solid #3A3A3A;
  border-radius: 20px;
  margin: 0.1rem 0.1rem;
  padding: 3rem 3rem;
}

figure > figcaption {
  text-align: center;
}

.tactor-nav-button {
  float: right;
  width: 8rem;
  background: #3A3A3A;
  border: .1rem solid #3A3A3A;
  border-radius: 1.5rem;
  color: #FFFFFF;
  text-transform: uppercase;
  margin-top: .5rem;
  padding: .3rem .3rem !important;
  align-items: center !important;
}

.sub-row.no-border{
  margin-top: 1rem;
  border: none !important;
  border-radius: 20px;
}

.tactor-tip{
  padding: 1rem;
  width: 100%;
  height: auto;
}

.tactor-tip.btn-spacing{
  padding-bottom: 3.5rem;
}

.tip-button{
  float: right;
  width: 4rem;
  background: #CACACA;
  border: .1rem solid #3A3A3A;
  border-radius: 1.5rem;
  color: #3A3A3A;
  text-transform: uppercase;
  padding: .3rem .1rem !important;
  align-items: center !important;
}

.tactor-tip-image{
  width: 100%;
  height: auto;
}

.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

section .home-connect-table{
  margin-left: 0rem !important;
}

.home-connect-box{
  border-radius: 1rem;
  border: 1px solid #3A3A3A !important;
  background: white !important;
  width: 100% !important;
  height:100% !important;
  padding:0rem 2rem;
  margin-bottom: .8rem;
  margin-top: 2rem;
}

.connection-button {
  float: right;
  width: 8rem;
  background: #3A3A3A;
  border: .1rem solid #3A3A3A;
  border-radius: 1.5rem;
  color: #FFFFFF;
  text-transform: uppercase;
  margin-top: 3rem;
  padding: .3rem .3rem !important;
  align-items: center !important;
}

h3.serial-connect{
  margin-top: 5rem;
  margin-bottom: 2rem;
}

.serial-select select{
  /* A reset of styles, including removing the default dropdown arrow */
  appearance: none;
  /* Additional resets for further consistency */
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;

  outline: none;
}

.serial-select select::-ms-expand {
  display: none;
}

button.serial-connect-button {
  width: 40%;
  background: #3A3A3A;
  border: .1rem solid #3A3A3A;
  border-radius: 1.5rem;
  color: #FFFFFF;
  text-transform: uppercase;
  margin-top: 3rem;
  padding: .3rem .3rem !important;
  align-items: center !important;
}

.centered-box{
  display: flex;
  justify-content: center;
  align-items: center;
}

:root {
  --select-border: #CACACA;
  --select-arrow: var(--select-border);
}

.serial-select{
  width: 100%;
  min-width: 15ch;
  border: 1px solid var(--select-border);
  border-radius: 2em;
  padding-left: 1.25em;

  font-size: 1rem;
  cursor: pointer;
  line-height: 2.5;
  background-color: #F0F0F0;
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  color: #9C9C9C;
}

.serial-select::after {
  content: "";
  width: 0.8em;
  height: 0.52em;
  margin-right: 1.5rem;
  background-color: var(--select-arrow);

  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  justify-self: end;
}

.serial-select select, .serial-select::after {
  grid-area: select;
}

.highlite{
  background-color: #CACACA;
  border-radius: .5rem;
  font-weight: bold;
  padding: .15rem .35rem;
  color:#3A3A3A;
}

.suggested{
  border-radius: 1rem;
  border: 5px solid #F4B321;
}

.dashboard-box{
  border-radius: 1rem;
  border: 1px solid #CACACA;
  padding:0rem 1rem 2rem 1rem;
  margin-bottom: .8rem;
  margin-top: 2rem;
}

.graph{
  height:12rem;
}


.pp img{
    display: inline-block;
    margin-right: 1rem;
}

.name-label{
  display:inline-block !important;
  display: inline-block;
font-weight: 400;
font-family: 'Roboto', sans-serif;
font-size: 14px;
margin-bottom: 0rem;
}

.text-input{
  border: 1px solid #3A3A3A;
  border-radius: 5px;
  margin-left: 1rem;
  padding-left: .5rem;
}

.name-input{
  margin-top: 2rem;
}

.archive{
  display:inline-block;
  border:none !important;
  background: none !important;
}

.archive img{
  margin-left: .3rem;
}

.buttons button{
  border: 1px solid #3A3A3A;
  border-radius: 20px;
  width: 6rem;
  margin: .3rem;
}

.buttons{
  margin-top:3rem;
}

.button-white{
  background: #FFFFFF;
  color: #3A3A3A;
  text-transform: uppercase;
}

.button-black{
  background: #3A3A3A;
  color: #FFFFFF;
  text-transform: uppercase;
}

#activated{
  float: right;
  width: 20%;
  background: #3A3A3A;
  border: .1rem solid #3A3A3A;
  color: #FFFFFF;
  text-transform: uppercase;
  padding: .3rem .3rem !important;
  align-items: center !important;
}

#deactivated{
  float: right;
  width: 20%;
  background: #F5F5F5;
  border: .1rem solid #3A3A3A;
  color: #3A3A3A;
  text-transform: uppercase;
  padding: .3rem .3rem !important;
  align-items: center !important;
}

input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

/* .slider {
  width: 88% !important;
  margin-left: auto; 
  margin-right: 0;
  border: 0px solid #9C9C9C;
  border-radius: 10px;
} */

.irs--flat .irs-bar {
  background-color: #F4B321 !important;
}

.irs--flat .irs-handle > i:first-child {
  background-color: #3A3A3A !important;
}

.irs--flat .irs-grid-pol {
  background-color: #3A3A3A !important;
}

.irs--flat .irs-grid-text {
  color: #3A3A3A !important;
}

/* .irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
  visibility: hidden !important;
  color: #3A3A3A !important;
  background-color: transparent !important;
}

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  visibility: hidden !important;
  border-top-color: transparent !important;
}

.irs--flat .irs-min,
.irs--flat .irs-max {
  visibility: hidden !important;
}

.irs--flat .irs-single {
  visibility: hidden !important;
} */

.irs--flat {
  top: -1rem;
}

.irs--flat .irs-shadow {
  background-color: #3A3A3A !important;
}

/* .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 5px;
  height:15px;
  background: #3A3A3A;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #4CAF50;
  cursor: pointer;
} */

.slider-div{
  margin-top:1rem;
}

.slider-value, .slider-label{
  display: inline-block;
  font-weight:400;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  margin-bottom: 0rem;
}

.slider-label{
  margin-left:.5rem;
}

.slider-icon{
  margin-top:0rem;
  margin-right:0rem;
  width:5%;
}

.slider-value{
  margin-right: 0rem;
}

.dashboard {
  font-size: 20px !important;
}

.main-content{
  max-width: 75vw;
  padding: 1rem 4rem;
}

.page-content{
  max-width: 98vw;
}

h2 {
  font-family: 'Roboto', sans-serif !important;
  font-size:30px !important;
  font-weight: 500 !important;
  line-height: 3.8rem !important;
}

h3{
  font-family: 'Roboto', sans-serif !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

h4{
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin:1rem;
  color: #000;
  max-width: 9rem;
}

h5{
  font-family: 'Roboto', sans-serif !important;
  font-size: 11px !important;
  font-weight: 400 !important;
}

h6{
  margin: .5rem;
}

.row .touchgen{
  min-height:8rem;
}

.touchgen-box{
  border-radius: 1rem;
  border: 1px solid #CACACA;
  width: 13rem;
  height:13rem;
  padding:0rem 1.6rem;
  margin-bottom: .8rem;
  margin-top: 2rem;
  cursor: pointer;
}

.row .tactorinfo{
  min-height:25rem;
}

.tactorinfo-box{
  border-radius: 1rem;
  border: 1px solid #CACACA;
  width: 30rem;
  height:13rem;
  padding:0rem 1.6rem;
  margin-bottom: .8rem;
  margin-top: 2rem;
}

.generator-logo{
  margin: auto;
}

/*
.main{
  display: table;
  width:100%;
}

.main, .left-col {
  display: table-cell;
}

.left-col {

} */


/* .navbar-nav .active a::after {
  border-bottom: 5px solid #000000;
  content: " ";
  left: 0;
  position: absolute;
  right: 0;
} */

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

.row .touchgen{
  min-height:8rem;
}

.touchgen-box{
  border-radius: 1rem;
  border: 1px solid #CACACA;
  width: 13rem;
  height:13rem;
  padding:0rem 1.6rem;
  margin-bottom: .8rem;
  margin-top: 2rem;
}

.tactortop-box{
  border-radius: 1rem;
  width: 20rem;
  height:12rem;
  padding:0rem 2rem;
  margin-bottom: .8rem;
  margin-top: 2rem;
}

.tactoroverview{
  font-weight:700;
}

.tactorside-box{
  border-radius: 1rem;
  width: 20rem;
  height:10rem;
  padding:0rem 2rem;
  margin-bottom: .8rem;
  margin-top: 2rem;
}

.tactorplacement-box{
  border-radius: 1rem;
  width: 20rem;
  height:24.9rem;
  padding:0rem 2rem;
  margin-bottom: .8rem;
  margin-top: 2rem;
}

.row.tactor-emotion{
  padding: 1.2rem;
  width: 100%;
  margin:auto;
}

img.tactor-emotion{
  margin-bottom:.1rem;
  margin-right:.3rem;
  margin-left: .3rem;
}

h4.tactor-emotion{
  margin-top: 0rem;
  margin-bottom: 1rem;
  margin-left: auto;
width: 70px;
margin-right: auto;
}

h4.activated{
  margin:1rem 2rem;
  max-width: none !important;
}

div.buttons{
  margin-right:2rem !important;
  margin-left: 2rem !important;
}

#archive-functions{
  width: 100%;
  float: right;
  margin-right: 0rem !important;
}

#archive-functions button{
  margin-left: 0.75rem !important;
}

.generator-logo{
  margin: auto;
}

.row.left-nav{
  margin-left:-30px !important;
}

ul.left-nav.tinfo{
  margin-left:-20px !important;
}

.sidebar.tinfo{
  padding-top:4rem !important;
}

ul.left-nav.archive{
  margin-left:-20px !important;
}

.sidebar.archive{
  padding-top:4rem !important;
}


img.tactortop, img.tactorside, img.tactorplacement{
  width:100%;
}

.tactselected{
  max-width:230px !important;
}

.tactorinfonav-archive{
  width:80%;
  padding-left:0;
  margin-left: 3.8rem;
  margin-bottom: .8rem;
  border-radius: 1rem;
  cursor: default !important;
}

.tactorinfonav-archive.activated{
  border: 2px solid #F4B321;
  background-color: #F4B321;
}

.tactorinfonav-archive.not-activated{
  border: 0px solid #CACACA;
}

/* .tactorinfonav-archive .tactoricon.active{
  border: 2px solid #F4B321;
  border-radius: 1rem;
}

.tactorinfonav-archive .tactoricon.not-active{
  background-color: none;
  border-radius: 0rem;
} */

.archive-block{
  background: #F5F5F5;
  border: 1px solid #CACACA;
  box-sizing: border-box;
  border-radius: 20px;
  height: 80%;
  margin-top: 1.69rem;
}

.archive-block .header{
  margin-left: 2.13rem;
  margin-right: 2.13rem;
  margin-top: 1.75rem;
}

.archive-block .header .title{
  line-height: 19px;
  padding-left: 0;
  font-family: 'Roboto', sans-serif !important;
  font-size: 20px !important;
  font-weight: 500 !important;

  color: #3A3A3A;
  float: left !important;
}

.username{
  line-height: 19px;
  padding-right: 0;
  padding-left: 0;
  margin-left: 0;
  margin-right: 0;
  text-align: right;
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;

  color: #3A3A3A;
  float: right;
}

.archive-block .data-block{
  height: inherit;
  margin-bottom: 1.75rem;
  margin-left: 1.75rem;
  margin-right: 1.75rem;
  margin-top: 3%;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 10px;
  overflow-y: auto;
}

.archive-block .data-block table{
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.archive-block .data-block table thead tr th{
  padding: 10px;
  background-color: #3A3A3A;
  color: #FFFFFF;
  
  position: sticky;
  top: 0;
}

.archive-block .data-block table thead tr th:first-child {
  border-radius: 10px 0 0 0;
}

.archive-block .data-block table thead tr th:last-child {
  border-radius: 0 10px 0 0;
}

.archive-block .data-block table thead tr th:only-child{
  border-radius: 10px 10px 0 0;
}

.archive-block .data-block table tbody tr{
  border-bottom: 2px solid #F5F5F5;
  cursor: pointer !important;
}

.archive-block .data-block table tbody tr td{
  padding: 8px;
}

.archive-block .data-block table tbody tr:nth-child(even){
  background-color: #F5F5F5;
}

.archive-block .data-block table tbody tr:hover{
  background-color: #F4B321;
  color: #3A3A3A;
}

.archive-block .footer{
  margin-top: 1.75rem;
  margin-left: 1.75rem;
}

.archive-block .footer .note{
  margin-left: 0.5rem;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: left;

  color: #3A3A3A;
}

.button-group{
 margin-right: 1.75rem;
}

.button-group button{
  border: 1px solid #3A3A3A;
  border-radius: 20px;
  width: 6rem;
  margin: .3rem;
}

.img-button {
  cursor: pointer !important;
}

.Toastify__toast--default {
  background-color: #3A3A3A !important;
  color: #F5F5F5 !important;
}

.Toastify__progress-bar--default {
  background: #F4B321 !important;
}

.Toastify__close-button--default {
  color: #FFF !important;
}

#play-icon, #pause-icon {
  cursor: pointer;
}